<template>
  <v-container>
    <v-card flat style="background-color: transparent; padding: 0">
      <v-card-text class="pa-0">
        <v-form ref="loginForm" v-model="formValid" lazy-validation v-on:submit.prevent style="max-width: 400px">
          <v-container fluid class="pa-0" :style="{'margin-top': $vuetify.breakpoint.mdAndUp ? '75px' : '0px'}">
            <v-row>
              <v-col>
                <span class="text-h4 logo--text font-weight-bold">Log</span>
                <span class="text-h4 logo--text font-weight-light">In</span>
              </v-col>
            </v-row>
            <v-row class="mt-5 mb-5">
              <v-col cols="5" class="mt-3">
                <v-divider/>
              </v-col>
              <v-col cols="2">
                <span class="">or</span>
              </v-col>
              <v-col cols="5" class="mt-3">
                <v-divider/>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <v-text-field
                    outlined
                    dense
                    color="#00BC74"
                    hide-details="auto"
                    type="email"
                    prepend-inner-icon="mdi-email"
                    v-model="loginData.email"
                    :rules="emailRules"
                    label="Email"
                    key="loginEmail"
                />
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col>
                <v-text-field
                    outlined
                    dense
                    color="#00BC74"
                    hide-details="auto"
                    prepend-inner-icon="mdi-key"
                    :append-icon="passIcon"
                    v-model="loginData.password"
                    :rules="[rules.required, rules.min]"
                    label="Password"
                    :type="passFieldType"
                    key="loginPassword"
                    @click:append="showOrHide()"
                />
                <div class="extras" style="float: right;">
                  <a href="#" class="logo--text">Forgot Password?</a>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col>
                <v-btn
                    color="#00BC74"
                    class="white--text"
                    block
                    @click.prevent.stop="loginHandler"
                >
                  Login
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <g-overlay :is-relay="isOverlay" />
  </v-container>
</template>

<script>
import Validation from "../mixins/Validation";
import User from "../../services/User";
import GOverlay from "../GOverlay";

export default {
  name: "GPLogin",
  components: {GOverlay},
  mixins: [Validation],
  data() {
    return {
      formValid: true,
      isOverlay: false,
      passFieldType: "password",
      passIcon: "mdi-eye-off",
      signUp: {
        firstName: '',
        lastName: '',
        orgName: '',
        email: '',
        password: ''
      },
      loginData: {
        email: '',
        password: '',
        device_name: 'Browser'
      },
    }
  },
  methods: {
    async loginHandler() {
      if (!this.$refs.loginForm.validate()) {
        return;
      }

      this.isOverlay = true;

      await User.csrf();

      try {
        let {data: {data}} = await User.login(this.loginData);
        this.$store.commit("auth/SET_TOKEN", data.token);
        this.$store.commit("auth/SET_AUTHENTICATED", true);
        this.redirectHandler();
      } catch (error) {
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          text: error.response.data.message
        });
        this.isOverlay = false;
      }
    },
    redirectHandler() {
      this.isOverlay = false;
      this.$router.push({name: "Dashboard", path: "/dashboard"});
    },
    showOrHide() {
      if(this.passFieldType === "password") {
        this.passFieldType = "text";
        this.passIcon = "mdi-eye"
      } else {
        this.passFieldType = "password";
        this.passIcon = "mdi-eye-off"
      }
    }
  }
}
</script>


<style lang="scss" scoped>

.extras {
  text-align: center;

  a {
    text-decoration: none;
  }
}

.error-message {
  color: red;
}
</style>
